import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";


export default class ExpandIndex {
    constructor() {
        this.http = new HttpHandler();
    }

    /**
     * 获取id获取缓存
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCacheById(data) {
        return this.http.get(`/api/expand/getCacheById`, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    /**
     * 添加内置影视资源
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    insideTvAdd(data) {
        return this.http.post(`/api/insideTv/addTv`, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    /**
     * 批量上传文件到文件快传
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    filesUpload(data) {
        return this.http.post('/api/file/saveToFKC', data, HttpConstant.MUL_FORM_REQ);
    }

    directoryFile(data) {
        return this.http.get('/api/file/directoryFile', data, HttpConstant.FORM_DATA_REQ);
    }

    appToken(data) {
        return this.http.get('/api/expand/appToken', data, HttpConstant.FORM_DATA_REQ);
    }


}